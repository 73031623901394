.footer {
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: #033594;
    color: #FFF;
    height: 5%;
    margin-top: 10px;
    padding-top: 10px;
  }
  