.error {
  color: rgb(237, 28, 36);
  font-weight: bold;
  
}
.successMessage {
    color: rgb(55, 125, 34);
    font-weight: bold;
}
.container {
  /* width: 80vw; */
  /* height: 70vh; */
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(108deg, #e0e0f9 0%, #dadaff 100%);
  border-radius: 2%;
  padding: 10px;
}
.container-box {
  display: block;
  width: 100%;
  max-width: 700px;
  overflow-wrap: break-word;
  margin: 5px;
}