.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: purple;
    color: white;
  }
  .topnav-left {
    float: left;
  }
  
  .padding-10 {
    padding: 10px;
  }  

  .bg-dark-blue {
    background-color: #033594;
  }