/* Global styles */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

.signup-link {
    color: rgb(13, 110, 253);
  }
.number {
    width: 250px;
    margin: auto;
    height: 50;
}
* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  outline: none;
}

/* Container styles */
.otpContainer {
  width: 20vw;
  height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(108deg, #e0e0f9 0%, #dadaff 100%);
  margin-top: 5vw;
  margin-left: 5vw;
}

/* Card styles */
.card {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 10px;
}

/* Header styles */
.label2 {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  color: #28152f;
}

/* Input field styles */
.input {
  width: 300px;
  border-radius: 6px;
  border: 1.5px solid #dee1ea;
  padding: 12px 20px;
}

/* Input field focus styles */
.input:focus {
  border-color: #506cff;
}

/* Countdown text styles */
.countdown-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0px;
}

/* Countdown text paragraph styles */
.countdown-text > p {
  font-size: 13px;
  font-weight: 400;
  color: #2d1d35;
}

/* Countdown text button styles */
.countdown-text > button {
  background-color: #fff;
  border: none;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

/* Submit button styles */
.submit-btn {
  width: 100%;
  border: none;
  color: #fff;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  padding: 10px;
  background: linear-gradient(91deg, #9076fc 0%, #6950ff 100%);
  transition: all ease 0.6s;
}

/* Submit button hover styles */
.submit-btn:hover {
  color: #6950ff;
  background: #e6e0ffbf;
}
.mt-10 {
  margin-top: 10 !important;
}
